<script setup lang="ts">
const appConfig = useAppConfig()

const { t, locale }: any = useI18n()
const localePath = useLocalePath()

const route = useRoute()

const url = useRequestURL()

// const slug = route.params.slug ?? ''
const slug = computed(() => _get(route.params.slug ?? [], 0, ''))

const routeNameCheck = computed(() => _includes(String(route.name), 'index'))

const authenticated = useAuthenticated()

const sitedata: any = useSiteData()

const cartKey = sitedata.value?.site_type === 4 ? 'cart-shop' : 'cart'

const sitedataCategory = computed(() => sitedata.value?.category?.length ? [...[{ name: t('homepage') }], ...sitedata.value?.category] : [])

const accountItems = ref<any>([])

if (authenticated.value) {
  accountItems.value = [
    // [{
    //   label: 'ben@example.com',
    //   slot: 'account',
    //   disabled: true,
    // }],
    [{
      label: t('account'),
      icon: 'i-heroicons-user-solid',
      click: async () => {
        await navigateTo(localePath('/account'))
      },
    }, {
      label: t('orders'),
      icon: 'i-heroicons-shopping-bag-16-solid',
      click: async () => {
        await navigateTo(localePath('/orders'))
      },
    }],
    [{
      label: t('signout'),
      icon: 'i-heroicons-arrow-left-on-rectangle',
      click: async () => {
        await Promise.all([
          useLogout(),
          localStorage.removeItem(cartKey),
          appConfig.locales.forEach((item: any) => {
            localStorage.removeItem(`${cartKey}-${item}`)
          }),
        ])
        await navigateTo({ path: localePath('/login'), query: { redirect: url.href } })
      },
    }],
  ]
}
else {
  accountItems.value = [
    [{
      label: t('login'),
      icon: 'i-heroicons-chevron-right-16-solid',
      click: async () => {
        await navigateTo({ path: localePath('/login'), query: { redirect: url.href } })
      },
    }],
    [{
      label: t('register'),
      icon: 'i-heroicons-chevron-right-16-solid',
      click: async () => {
        await navigateTo(localePath('/register'))
      },
    }],
  ]
}
</script>

<template>
  <div
    class="hidden z-40 peer-checked:flex w-11/12 mx-auto lg:mx-0 flex-col
                justify-end items-center gap-y-8 p-6
                rounded-xl bg-white lg:flex
                lg:gap-y-0 lg:gap-x-2 lg:p-0
                lg:flex-row lg:bg-transparent lg:w-10/12"
  >
    <div class="block w-full lg:w-max print:hidden">
      <ul
        v-if="sitedataCategory && sitedataCategory?.length > 0"
        class="text-gray-500 font-medium flex flex-col lg:flex-row gap-y-5 lg:gap-y-0 gap-x-6 px-3"
      >
        <li
          v-for="(item, index) in sitedataCategory"
          :key="`FYWmQa${index}`"
        >
          <NuxtLinkLocale
            :to="item?.slug ? `/${item?.slug}` : '/'"
            :hreflang="locale"
            :class="(item?.slug === slug || (!item?.slug && routeNameCheck)) ? 'md:px-3' : 'md:px-3 group'"
          >
            <div
              :class="(item?.slug === slug || (!item?.slug && routeNameCheck)) ? 'relative' : 'relative group'"
              :style="(item?.slug === slug || (!item?.slug && routeNameCheck)) ? `color: ${sitedata?.primary_color ?? '#0F9D58'};` : ''"
            >
              <span
                :class="(item?.slug === slug || (!item?.slug && routeNameCheck)) ? '' : 'group-hover:text-slate-700'"
              >
                {{ item?.name }}
              </span>
            </div>
          </NuxtLinkLocale>
        </li>
      </ul>
    </div>

    <div
      class="w-full md:pl-5 gap-x-5 sm:w-max flex min-w-max flex-col sm:flex-row print:hidden"
    >
      <button
        v-if="sitedata?.layout !== 'singlepage' && sitedata?.hascart"
        type="button"
        :title="t('cart')"
        class="flex items-center justify-start w-full py-3 px-5 rounded-xl text-center transition hover:bg-slate-700 active:bg-slate-800 focus:bg-slate-500 sm:w-max"
        :style="`background-color: ${sitedata?.primary_color ?? '#0F9D58'}`"
        @click="navigateTo(localePath('/cart'))"
      >
        <UIcon name="i-heroicons-shopping-cart-solid" class="text-white" />
        <span class="block text-white font-semibold ml-2">
          {{ t('cart') }}
        </span>
      </button>

      <UDropdown
        v-if="sitedata?.login_logout" :items="accountItems"
        :ui="{ item: { disabled: 'cursor-text select-text' } }"
        :popper="{ placement: 'bottom-start' }"
        class="py-2 my-5 sm:my-0"
      >
        <UAvatar icon="i-heroicons-user-solid" size="sm" />

        <span class="sm:hidden truncate text-lg px-3 text-gray-900">{{ t('account') }}</span>

        <template #account="{ item }">
          <div class="text-left">
            <p>
              Signed in as
            </p>
            <p class="truncate font-medium text-gray-900">
              {{ item.label }}
            </p>
          </div>
        </template>

        <template #item="{ item }">
          <span class="truncate text-lg">{{ item.label }}</span>

          <UIcon :name="item.icon" class="flex-shrink-0 h-4 w-4 text-gray-400 ms-auto" />
        </template>
      </UDropdown>

      <LangSelect />
    </div>
  </div>
</template>
